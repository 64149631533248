.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  font-size: calc(1px + 2vmin);
  color: white;
}

.App-logo {
  display: block;
  max-width: 90%;
  width: 300px;
  height: "auto";
  margin: 0 auto;
}

.App-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-link {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  border: 2px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  padding: 1rem;
  margin: 4px;
  text-decoration: none;
  width: 11.5rem;
  text-align: left;
  transition: all .2s ease-in;
}

.App-link:hover {
  color: black;
  background-color: white;
}

.App-span {
  display: block;
  font-size: .8rem;
}

.App-link-icon {
  font-size: 3rem;
  width: 3.5rem;
}

@media screen and (max-width: 600px) {
  .App-link {
    width: 100%;
    margin: .4rem 0;
  }

  .App-main {
    flex-direction: column;
  }
}
